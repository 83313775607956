#Dashboard, #DashboardBottom {
  .panel-heading, .panel-body, .panel-footer {
    text-align: center;
    overflow: hidden;
  }
  .panel-body, .panel-footer {
    color: #333;
  }
  #chart_div, #piechart {
    margin: 0 auto;
  }
  #piechart {
    width: 200px;
    margin-left: -15px;
  }
}

#DashboardBottom .panel-body {
  overflow: scroll;
}

#MainContent {
  border: 5px solid #2D882D;
  padding: 20px;
  h1 {
    font-size: 2.25em;
    font-family: 'Acre';
    font-weight: 100;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    &.lead {
      font-size: 18px;
    }
  }
  .panel-collapse {
    color: #333;
  }
  .login-page {
    color: #333;
    margin: 0 auto;
  }
}

.dropzone {
  display: none;
}

#FontAwesomeList {
  .text-muted {
    display: none;
  }
}

.row {
  margin-left: 0;
}

#accordion {
  .panel {
    .panel-collapse {
      .category-photos {
        position: relative;
        display: none;
      }
      .panel-body {
        h4 {
          color: #333;
        }
        .explanation {
          font-size: 16px;
          color: #666;
          line-height: 150%;
        }
        .cover-image {
          height: 50px;
          border-radius: 50%;
        }
        .image-roll {
          display: block;
          overflow: scroll;
          text-align: center;
          .add-images-button {
            padding: 10px 5px;
            i {
              font-size: 48px;
            }
          }
          .active {
            border: 4px solid #EE0000;
          }
          &.photo-categories {
            text-align: left;
            img {
              height: 75px;
            }
            #AddCategory {
              position: relative;
              display: none;
              color: #333;
              &.active {
                display: inline-block;
                border: none;
              }
              form {
                padding: 20px 10px;
                text-align: center;
                input[type="text"] {
                  position: relative;
                  top: 20px;
                  width: 100px;
                  display: block;
                }
                input[type="submit"] {
                  display: block;
                  position: relative;
                  top: 20px;
                  width: 100px;
                  margin-top: 10px;
                }
              }
            }
          }
          .dropzone-open {
            margin: 10px;
          }
          .dropzone {
            margin: 10px 5px;
          }
          figure {
            margin: 10px 5px;
            border: 2px solid #CCC;
            border-radius: 10px;
            padding: 2px;
            display: inline-block;
            //width: 120px;
            width: 160px;
            text-align: center;
            img {
              width: 140px;
              height: 140px;
              box-sizing: border-box;
              margin: 5px 5px 5px 0;
            }
            .rename-field {
              width: 145px;
              color: #333;
              margin-bottom: 5px;
            }
            .image-rename, .image-remove, .image-activate {
              font-size: 24px;
              display: inline-block;
              margin: 5px;
            }
            button {
              margin: 0;
              padding: 0;
              background: none;
              border: none;
              outline: none !important;
            }
            .image-remove { color: #AA3939 }
            .image-rename { color: #2D882D }
            .image-activate { color: #226666 }
          }
        }
        .modal {
          color: #333;
          .modal-footer {
            button, .btn {
              display: inline-block;
              margin: 2px 3px;
            }
          }
        }
      }
    }
  }
}

main {
  margin-top: 20vh;
  color: #EEE;
}
.row {
  width: 100%;
}
#MainSideBar,
#MainContent {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}
#MainContent {
  border: 5px solid #2D882D;
  padding: 20px;
}
#MainContent h1 {
  font-size: 2.25em;
  font-family: 'Acre';
  font-weight: 100;
  text-transform: uppercase;
}
#MainContent p {
  font-size: 16px;
}
#MainContent p.lead {
  font-size: 18px;
}
#MainContent .panel-collapse {
  color: #333;
}
#MainContent .login-page {
  color: #333;
  margin: 0 auto;
}
.dropzone {
  display: none;
}
#FontAwesomeList .text-muted {
  display: none;
}
.row {
  margin-left: 0;
}
#MainSideBar {
  border: 5px solid #AA3939;
  margin: 0;
  padding: 0;
}
#MainSideBar ul {
  padding: 0;
  list-style: none;
  width: 100%;
  height: 100%;
}
#MainSideBar ul li {
  margin: 3px 0;
  padding: 0;
  width: 100%;
}
#MainSideBar ul li a,
#MainSideBar ul li a:hover,
#MainSideBar ul li a:link,
#MainSideBar ul li a:visited {
  display: block;
  text-transform: uppercase;
  text-shadow: 2px -1px 2px rgba(0, 0, 0, 0.8);
  color: #E9E9E9;
  padding: 10px;
  line-height: 150%;
  text-decoration: none;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
}
#MainSideBar ul li a:hover,
#MainSideBar ul li a.active {
  background: #801515;
}
#accordion .panel .panel-collapse .category-photos {
  position: relative;
  display: none;
}
#accordion .panel .panel-collapse .panel-body h4 {
  color: #333;
}
#accordion .panel .panel-collapse .panel-body .explanation {
  font-size: 16px;
  color: #666;
  line-height: 150%;
}
#accordion .panel .panel-collapse .panel-body .cover-image {
  height: 50px;
  border-radius: 50%;
}
#accordion .panel .panel-collapse .panel-body .image-roll {
  display: block;
  overflow: scroll;
  text-align: center;
}
#accordion .panel .panel-collapse .panel-body .image-roll .add-images-button {
  padding: 10px 5px;
}
#accordion .panel .panel-collapse .panel-body .image-roll .add-images-button i {
  font-size: 48px;
}
#accordion .panel .panel-collapse .panel-body .image-roll .active {
  border: 4px solid #EE0000;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories {
  text-align: left;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories img {
  height: 75px;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories #AddCategory {
  position: relative;
  display: none;
  color: #333;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories #AddCategory.active {
  display: inline-block;
  border: none;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories #AddCategory form {
  padding: 20px 10px;
  text-align: center;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories #AddCategory form input[type="text"] {
  position: relative;
  top: 20px;
  width: 100px;
  display: block;
}
#accordion .panel .panel-collapse .panel-body .image-roll.photo-categories #AddCategory form input[type="submit"] {
  display: block;
  position: relative;
  top: 20px;
  width: 100px;
  margin-top: 10px;
}
#accordion .panel .panel-collapse .panel-body .image-roll .dropzone-open {
  margin: 10px;
}
#accordion .panel .panel-collapse .panel-body .image-roll .dropzone {
  margin: 10px 5px;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure {
  margin: 10px 5px;
  border: 2px solid #CCC;
  border-radius: 10px;
  padding: 2px;
  display: inline-block;
  width: 160px;
  text-align: center;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure img {
  width: 140px;
  height: 140px;
  box-sizing: border-box;
  margin: 5px 5px 5px 0;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure .rename-field {
  width: 145px;
  color: #333;
  margin-bottom: 5px;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-rename,
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-remove,
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-activate {
  font-size: 24px;
  display: inline-block;
  margin: 5px;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none !important;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-remove {
  color: #AA3939;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-rename {
  color: #2D882D;
}
#accordion .panel .panel-collapse .panel-body .image-roll figure .image-activate {
  color: #226666;
}
#accordion .panel .panel-collapse .panel-body .modal {
  color: #333;
}
#accordion .panel .panel-collapse .panel-body .modal .modal-footer button,
#accordion .panel .panel-collapse .panel-body .modal .modal-footer .btn {
  display: inline-block;
  margin: 2px 3px;
}
#SaveText {
  margin: 10px;
}
#Dashboard .panel-heading,
#DashboardBottom .panel-heading,
#Dashboard .panel-body,
#DashboardBottom .panel-body,
#Dashboard .panel-footer,
#DashboardBottom .panel-footer {
  text-align: center;
  overflow: hidden;
}
#Dashboard .panel-body,
#DashboardBottom .panel-body,
#Dashboard .panel-footer,
#DashboardBottom .panel-footer {
  color: #333;
}
#Dashboard #chart_div,
#DashboardBottom #chart_div,
#Dashboard #piechart,
#DashboardBottom #piechart {
  margin: 0 auto;
}
#Dashboard #piechart,
#DashboardBottom #piechart {
  width: 200px;
  margin-left: -15px;
}
#DashboardBottom .panel-body {
  overflow: scroll;
}

#MainSideBar {
  border: 5px solid #AA3939;
  margin: 0;
  padding: 0;
  ul {
    padding: 0;
    list-style: none;
    width: 100%;
    height: 100%;
    li {
      margin: 3px 0;
      padding: 0;
      width: 100%;
      a, a:hover, a:link, a:visited {
        display: block;
        text-transform: uppercase;
        text-shadow: 2px -1px 2px rgba(0,0,0,.8);
        color: #E9E9E9;
        padding: 10px;
        line-height: 150%;
        text-decoration: none;
        font-size: 15px;
        font-weight: 300;
        width: 100%;
      }
      a:hover, a.active {
        background: #801515;
      }
    }
  }
}

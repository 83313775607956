main {
  margin-top: 20vh;
  color: #EEE;
}
.row {
  width: 100%; // TODO: Make it screenwide on mobile
}
#MainSideBar, #MainContent {
  border-radius: 10px;
  background: rgba(0,0,0,.5);
}
@import 'admin/main';
@import 'admin/sidebar';
@import 'admin/photos';
@import 'admin/text';
@import 'admin/dashboard';
